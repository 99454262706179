.custom_credit_field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  position: relative;
  max-width: 350px;
  box-shadow: 0 0 10px 0 rgba(0 0 0 0.1);
  margin: 6px auto;
  height: 55px;
  border-radius: 15px;
  line-height: 55px;
  cursor: pointer;
  padding-left: 10px;
  color: #ffffff;
  background-color: #000000;
  font-family: Roboto-Regular;
}

.credit_bold {
  font-weight: bold;
}

.small_coins {
  height: 33px;
  width: auto;
  align-self: center;
  margin-right: 11px;
}

.center_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.custom_credit {
  font-size: 18px;
  border: none;
  color: #ffffff;
  line-height: 2.2;
  resize: none;
  background: transparent;
  width: 6ch;
  padding: 6px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input:focus {
  outline: none;
}

input.custom_credit {
  font-size: 18px;
  border: none;
  color: #ffffff;
  line-height: 2.2;
  resize: none;
  background: transparent;
  width: 6ch;
  padding: 6px;
}
