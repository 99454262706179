.credits_left_wrapper {
  width: 100%;
  padding: 0;
  font-size: 16px;
  margin-bottom: 45px;
  margin-top: 45px;
}

.total_credits {
  display: flex;
  font-size: 22px;
  margin: 0 auto 5px auto;
  background-color: #f9f9f9;
  border-radius: 15px;
  background: #f9f9f9;
  width: fit-content;
  align-items: center;
}

.credits_left {
  line-height: 35px;
  padding-left: 10px;
  padding-right: 20px;
  color: #000000;
}

.large_coin {
  height: 26px;
  width: auto;
}
