@keyframes notificationFromRight {
  0% {
    transform: translateX(100vw);
    opacity: 0;
  }
  10% {
    transform: translateX(-10px);
    opacity: 1;
  }

  11% {
    transform: translateX(0);
    opacity: 1;
  }

  12% {
    transform: translateX(10px);
    opacity: 1;
  }

  13% {
    transform: translateX(0);
    opacity: 1;
  }

  90% {
    transform: translateX(0);
    opacity: 1;
  }

  95% {
    transform: translateX(-10px);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw);
    opacity: 1;
  }
}

.notification_toast {
  position: fixed;
  top: 5px;
  left: 0;
  right: 0;
  padding: 8px 20px;
  max-width: 670px;
  font-size: 20px;
  z-index: 600;
  margin: 0 auto;
  cursor: pointer;
  animation: notificationFromRight 3s ease-in;
}
