.credit_button {
  max-width: 350px;
  color: #343a40;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin: 7px auto;
  height: 55px;
  border-radius: 15px;
  line-height: 1.2;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.credit_bold {
  font-weight: bold;
}

.credits_coins {
  display: flex;
  align-items: center;
}

.credit_regular {
  font-weight: normal;
}

.small_coins {
  height: 33px;
  width: auto;
  align-self: center;
  margin-right: 11px;
}

.long_press {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.active {
  color: #ffffff;
  background-color: #343a40;
}
