$success_alert: #d7f9e6;
$success_alert_color: #0db18d;
$error_alert: #ffcece;
$error_alert_color: #ff295c;
$info_alert: #cdc8ff;
$info_alert_color: #3022b3;
$warning_alert: #ffdbbf;
$warning_alert_color: #e38213;

.notification_alert_wrapper {
  .toast_success,
  .toast_error,
  .toast_info,
  .toast_warning {
    height: 50px;
    border-radius: 15px;
    opacity: 1;
    border: unset;
    display: flex;
    align-items: center;
  }

  .toast_success {
    background: $success_alert 0% 0% no-repeat padding-box;
    color: $success_alert_color;
  }

  .toast_error {
    background: $error_alert 0% 0% no-repeat padding-box;
    color: $error_alert_color;
  }

  .toast_info {
    background: $info_alert 0% 0% no-repeat padding-box;
    color: $info_alert_color;
  }

  .toast_warning {
    background: $warning_alert 0% 0% no-repeat padding-box;
    color: $warning_alert_color;
  }

  .toast_icon {
    margin-left: 10px;
    margin-right: 10px;
  }

  .toast_text {
    font-size: 16px;
    font-weight: 500;
  }
}
