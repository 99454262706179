.credit_packages_wrapper {
  width: 100%;
  font-size: 19px;
  margin-top: 30px;
  margin-bottom: 38px;
  animation: slidein 0.3s ease-in;
}

.credits_expire_message {
  color: #343a40;
  margin-bottom: 23px;
  line-height: 1.2;
}

.center_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
