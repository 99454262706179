.temp_single_credit_btn {
  /*TODO -remove this class*/
  z-index: 100000;
  position: fixed;
  width: 100%;
  background: #fff;
}

.joinButtons {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 100%;
  margin-bottom: 10px;
}

.controls {
  width: 100%;
  max-width: 350px;
  margin: 0 auto 25px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}
